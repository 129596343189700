@import "themes/GlobalStyle.css";

.navMainDrawer a {
    color: var(--max-color-text);
}

.navMainDrawer a:hover {
    background: var(--max-color-nav-hover);
    border-radius: var(--max-length-button-radius);
}

.navMainDrawer .MuiListItemText-primary {
    font-size: var(--max-font-size-nav);
}

.navMainList .Mui-selected {
    background: var(--max-color-nav-selected);
}