/* **********************
    CARD
********************** */
.MuiCard-root {
    padding: var(--max-length-medium1);
}

.MuiCardHeader-root {
    color: var(--max-color-text);
}

.MuiCardHeader-root .MuiTypography-root {
    font-size: var(--max-font-size-title);
    color: var(--max-color-title);
    /* font-weight: bold; */
}

.MuiCardHeader-root .MuiCardHeader-subheader {
    font-size: var(--max-font-size-subtitle);
    /* font-weight: normal; */
}

.MuiCardContent-root {
    font-size: var(--max-font-size-text);
    color: var(--max-color-text);
}

/* **********************
    DATA GRID
********************** */
.MuiDataGrid-columnHeaders {
    background-color: var(--max-color-grey-30);
}   

/* **********************
    SELECT
********************** */
.MuiInputBase-root {
    height: 2.5rem;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
}