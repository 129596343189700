@import "themes/GlobalStyle.css";

.dataUnits {
    display: flex;
    color: var(--max-color-label);
    margin-top: var(--max-length-small1);
    margin-bottom: var(--max-length-small1);
}

.dataUnitsIcon {
    margin-left: var(--max-length-small1);
    margin-right: var(--max-length-small1);
    vertical-align: middle;
}

.dataUnitsText {
    font-size: var(--max-font-size-subtitle);
}