@import "themes/GlobalStyle.css";

[data-amplify-authenticator] [data-amplify-router] {
    border-radius: var(--max-length-medium1);
    padding: var(--max-length-medium1);
}

[class*=amplify] {
    font-family: 'Roboto', 'Arial', 'Segoe UI';
}

:root {
    --amplify-fonts-default-variable: "Roboto, Arial";
    --amplify-fonts-default-static: "Roboto, Arial";
    --amplify-colors-brand-primary-10: var(--max-color-primary-20);
    --amplify-colors-brand-primary-20: var(--max-color-primary-40);
    --amplify-colors-brand-primary-40: var(--max-color-primary-60);
    --amplify-colors-brand-primary-60: var(--max-color-primary-80);
    --amplify-colors-brand-primary-80: var(--max-color-primary);
    --amplify-colors-brand-primary-90: var(--max-color-primary-80);
    --amplify-colors-brand-primary-100: var(--max-color-primary-60);
    /* --amplify-colors-brand-secondary-10: var(--max-color-accent-10);
    --amplify-colors-brand-secondary-20: var(--max-color-accent-20);
    --amplify-colors-brand-secondary-40: var(--max-color-accent-40);
    --amplify-colors-brand-secondary-60: var(--max-color-accent-60);
    --amplify-colors-brand-secondary-80: var(--max-color-accent-80);
    --amplify-colors-brand-secondary-90: var(--max-color-accent-90);
    --amplify-colors-brand-secondary-100: var(--max-color-accent);

    --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
    --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
    --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
    --amplify-colors-background-secondary: var(
        --amplify-colors-brand-primary-10
    );
    --amplify-components-tabs-item-border-color: var(
        --amplify-colors-neutral-60
    ); */
}
