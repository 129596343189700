@import url("https://fonts.googleapis.com/css?family=Roboto");

:root {
    --max-font: "Roboto";

    --max-color-primary: #bdce56;
    --max-color-primary-90: #c3d266;
    --max-color-primary-80: #cad777;
    --max-color-primary-70: #d0dc88;
    --max-color-primary-60: #d7e199;
    --max-color-primary-50: #dee6aa;
    --max-color-primary-40: #e4ebbb;
    --max-color-primary-30: #ebf0cc;
    --max-color-primary-20: #f1f5dd;
    --max-color-primary-10: #f8faee;

    --max-color-accent: #f1a341;
    --max-color-accent-90: #f2ac54;
    --max-color-accent-80: #f3b566;
    --max-color-accent-70: #f5be7a;
    --max-color-accent-60: #f6c78d;
    --max-color-accent-50: #f8d1a0;
    --max-color-accent-40: #f9dab3;
    --max-color-accent-30: #fae3c6;
    --max-color-accent-20: #fcecd9;
    --max-color-accent-10: #fdf5ec;

    --max-color-grey: #87939c;
    --max-color-grey-90: #939da5;
    --max-color-grey-80: #9fa8af;
    --max-color-grey-70: #abb3b9;
    --max-color-grey-60: #b7bec3;
    --max-color-grey-50: #c3c9cd;
    --max-color-grey-40: #cfd3d7;
    --max-color-grey-30: #dbdee1;
    --max-color-grey-20: #e7e9eb;
    --max-color-grey-10: #f3f4f5;
    
    --max-color-title: #2b2930;
    --max-color-text: #565360;
    --max-color-label: #908e9b;

    --max-color-hover-primary: var(--max-color-primary-20);
    --max-color-hover-accent: var(--max-color-accent-20);
    --max-color-hover-grey: var(--max-color-grey-20);

    --max-color-nav-hover: var(--max-color-accent-20);
    --max-color-nav-selected: var(--max-color-accent-20);

    --max-color-dark: #1e3538;
    --max-color-grey1: #666666;
    --max-color-grey2: #a5a5a5;
    --max-color-grey3: #e7e6e6;
    --max-color-select: #f5f5f5;
    --max-color-disabled: #e1dfe9;
    --max-status-green: #37bc64;
    --max-status-red: #de5050;
    --max-status-orange: #f9ae3f;
    --max-status-green-soft: #e2f8e9;
    --max-status-red-soft: #fff3f3;
    --max-status-orange-soft: #fff8de;

    --max-length-small1: 0.25rem;
    --max-length-small2: 0.375rem;
    --max-length-small3: 0.5rem;
    --max-length-medium1: 1rem;
    --max-length-medium2: 1.25rem;
    --max-length-medium3: 1.5rem;
    --max-length-large1: 2rem;
    --max-length-large2: 3rem;
    --max-length-large3: 4rem;
    --max-length-extralarge1: 5rem;
    --max-length-extralarge2: 6rem;
    --max-length-extralarge3: 7rem;

    --max-length-button-radius: 8px;

    --max-size-chart-medium: 11rem;
    --max-size-chart-large: 22rem; /* 352px */
    --max-color-chart-primary: var(--max-color-primary);
    --max-color-chart-accent: var(--max-color-accent);
    --max-color-chart-grey: var(--max-color-grey);

    --max-size-table-small: 5rem;
    --max-size-table-medium: 10rem;
    --max-size-table-large: 20rem;
    --max-color-table-header: var(--max-color-grey-20);
    --max-color-table-header-seperator: var(--max-color-grey-60);
    --max-color-table-hover: var(--max-color-grey-10);
    --max-color-table-selected: var(--max-color-grey-10);
    --max-color-table-highlight-primary: var(--max-color-primary-40);
    --max-color-table-highlight-accent: var(--max-color-accent-40);

    --max-size-score-circle-medium: 4rem;

    --max-font-size-header: 1.5rem; /* 24px */
    --max-font-size-title: 1.3rem; /* 20px */
    --max-font-size-nav: 0.875rem; /* 14px */
    --max-font-size-nav-icon: 1.5rem; /* 24px */
    --max-font-size-nav-icon-large: 3rem;
    --max-font-size-text: 0.875rem; /* 14px */
    --max-font-size-icon: 1rem;
    --max-font-size-subtitle: 0.8rem; /* 12px */

    --max-color-title-header: #ffffff;
    --max-color-background: #fafbfc;

    --max-length-drawerwidth: 15rem; /* 240px */
}

.body {
    background-color: var(--max-color-background);
    font-size: var(--max-font-size-text);
}

.sectionBreak {
    margin-top: var(--max-length-medium3);
}

.sectionTitle {
    margin-left: var(--max-length-medium3);
    font-size: var(--max-font-size-title);
    color: var(--max-color-text);
    text-transform: uppercase;
}

.text {
    font-size: var(--max-font-size-text);
}

.subtitle {
    font-size: var(--max-font-size-subtitle);
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}